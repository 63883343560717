import React from 'react';
import { Link } from 'react-router-dom';

const SpeakerCard = ({ speaker }) => {
  return (
    <Link
      to={speaker.link}
      target='_blank'
      className='flex 3xs:flex-row flex-col w-max bg-gray-50 rounded-[0.5em] shadow-md 3xs:h-[15em] h-auto hover:shadow-primary/20 hover:bg-primary hover:scale-[1.05] duration-75'
    >
      {/* =========================== Card START =========================== */}
      <div className='w-[12em] h-full border-[1px] border-gray-200 rounded-[0.5em] shadow-lg cursor-pointer flex flex-col text-[1em] bg-white overflow-hidden object-[50%] text-black'>
        <img
          src={speaker.image}
          alt='speaker'
          className='h-[70%] w-full object-cover'
        />
        <h1 className='text-[0.7em] font-bold px-[0.5em] mt-[0.5em]'>
          {speaker.name}
        </h1>
        <p className='text-[0.7em] font-medium px-[0.5em] py-[0.2em] h-[3.5em] w-full text-ellipsis overflow-hidden'>
          {speaker.affiliation}
        </p>
        <button className='w-[40%] text-gray-700  text-[0.7em] bg-primary mx-[0.5em] py-[0.2em] mt-auto mb-[0.5em] border-[1px] border-black rounded-[5px]'>
          See Detail
        </button>
      </div>
      {/* =========================== Card END =========================== */}
      {/* =========================== BIO START =========================== */}
      <p className='overflow-hidden text-ellipsis 3xs:w-[12em] w-[14em] max-h-full px-[0.5em] text-[0.8em] py-[0.5em]'>
        {speaker.biography}
      </p>
      {/* =========================== BIO END =========================== */}
    </Link>
  );
};

export default SpeakerCard;

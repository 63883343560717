import React from 'react';
import Heading from '../Heading';
import RegistrationTable from '../RegistrationTable';
import NoBubbleLayout from '../Layout/NoBubbleLayout';

import ResigtrationForm from '../../assets/Resigtration Form RAAISA 2024.pdf';
import DownloadTemplate from './DownloadTemplate';
import { PrimaryLinkButton } from '../uielements/Buttons';
import { CONFERENCE_EMAIL } from '../../Data/dummy';

const Registration = () => {
  return (
    <NoBubbleLayout id='registration' heading={'Registration'}>
      <p className='mt-[5vw] text-center text-black text-[0.8em]'>
        Fees for Registration to be paid in the following Bank Account by NEFT /
        IMPS : [if doing online transfer add to description "RAAISA" and e-mail
        transaction receipt to {CONFERENCE_EMAIL} ]
      </p>
      <section className='no_bubble_layout'>
        <Heading
          title={'Conference Participation Fees'}
          colorClass={
            'text-secondary md:text-[35px] sm:text-[25px] text-[16px]'
          }
        />
        <RegistrationTable />
        <div className='w-full flex items-center justify-center flex-col mt-[2em]'>
          <Heading
            title={'Paper Templates'}
            colorClass={
              'text-secondary md:text-[35px] sm:text-[25px] text-[16px]'
            }
          />
          <DownloadTemplate disableHeader />
        </div>
      </section>
      <section className='no_bubble_layout'>
        <Heading
          title={'Registration Form'}
          colorClass={
            'text-secondary md:text-[35px] sm:text-[25px] text-[16px]'
          }
        />
        <div className='flex flex-col items-center pt-[2em] gap-[0.5em]'>
          <a
            href={ResigtrationForm}
            download
            className="py-[0.5em] px-[1.5em] rounded-[0.2em] shadow-md secondary_btn"
          >
            <p className="text-[0.8em]">Click here to Download</p>
          </a>
          {/* <PrimaryLinkButton download>
            Will be Uploaded shortly...
          </PrimaryLinkButton> */}
        </div>
      </section>
      <div className='mt-[2em]'>
        <Heading
          title={'Consent To Publish Form'}
          colorClass={
            'text-secondary md:text-[35px] sm:text-[25px] text-[16px]'
          }
        />
        <div className='flex flex-col items-center pt-[2em] gap-[0.5em]'>
          {/* <button
            className="py-[0.5em] px-[1.5em] rounded-[0.2em] shadow-md secondary_btn group disabled:bg-gray-300 disabled:pointer-events-none"
            disabled
          >
            <p className="text-[0.8em]">Will be Uploaded shortly...</p>
          </button> */}
          <PrimaryLinkButton>Will be Uploaded shortly...</PrimaryLinkButton>
        </div>
      </div>
    </NoBubbleLayout>
  );
};

export default Registration;
